import { createTheme, defaultVariantColorsResolver } from "@mantine/core";
import { components } from "@assets/styles/components";

const variantColorResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input);

  if (input.color === "dark") {
    if (input.variant === "outline") {
      return {
        ...defaultResolvedColors,
        color: "var(--mantine-color-black)",
      };
    }
  } else if (input.color === "cyan") {
    if (input.variant === "outline") {
      return {
        ...defaultResolvedColors,
        hoverColor: "var(--mantine-color-cyan-filled-hover)",
      };
    }
  }

  return defaultResolvedColors;
};

export const theme = createTheme({
  // Colors
  primaryColor: "cyan",
  primaryShade: {
    light: 5,
    dark: 5,
  },
  black: "#000000",
  white: "#ffffff",

  // prettier-ignore
  colors: {
    dark:   [ "#C1C2C5", "#A6A7AB", "#909296", "#5C5F66", "#373A40", "#2C2E33", "#25262B", "#1A1B1E", "#141517", "#101113" ],
    cyan:   [ "#e4f8f8", "#caf2f1", "#b0eceb", "#7cdfdd", "#48d3d0", "#14c7c3", "#0f9a97", "#0b6e6c", "#064241", "#021615" ],
    green:  [ "#f6ffe2", "#edffc6", "#e4ffaa", "#d2ff71", "#c0ff38", "#afff00", "#88c600", "#618d00", "#3a5500", "#131c00" ],
    orange: [ "#feede3", "#fedbc7", "#fec9ab", "#fea673", "#fe823b", "#fe5f03", "#c54902", "#8d3401", "#541f01", "#1c0a00" ],
    pink:   [ "#fff3fb", "#ffe7f7", "#ffdbf3", "#ffc3eb", "#ffabe3", "#ff94dc", "#c673ab", "#8d527a", "#553149", "#1c1018" ],
  },

  variantColorResolver: variantColorResolver,

  // Fonts
  fontFamily: "Manrope, sans-serif",
  fontSizes: {
    xs: "clamp(6px, 0.9rem + 5vw, 12px)",
    sm: "clamp(7px, 0.95rem + 5vw, 14px)",
    md: "clamp(7.5px, 1rem + 5vw, 15px)",
    lg: "clamp(9px, 1.1rem + 5vw, 18px)",
    lgb: "clamp(9px, 1.11rem + 5vw, 19px)",
    xl: "clamp(11px, 1.2rem + 5vw, 22px)",
  },
  headings: {
    fontFamily: "'Monument Extended', sans-serif",
    sizes: {
      h1: {
        fontSize: "clamp(1.7rem, 1.1242rem + 3.6854vw, 3.75rem)",
        fontWeight: 800,
        lineHeight: 1.1,
      },
      h2: {
        fontSize: "clamp(1.5rem, 1.0787rem + 2.6966vw, 3rem)",
        fontWeight: 800,
        lineHeight: 1.1,
      },
      h3: {
        fontSize: "clamp(1.2rem, 0.9753rem + 1.4382vw, 2rem)",
        fontWeight: 500,
        lineHeight: 1.1,
      },
      h4: {
        fontSize: "clamp(1rem, 0.9438rem + 0.3596vw, 1.2rem)",
      },
      h5: {
        fontSize: "clamp(1.1rem, 0.9667rem + 1.0000vw, 1.7rem)",
        lineHeight: 1.1,
      },
    },
  },

  // Sizes
  breakpoints: {
    xxs: "22.5em", // 360
    xs: "36em", // 576
    sm: "48em", // 768
    md: "62em", // 992
    lg: "75em", // 1200
    xl: "88em", // 1408
  },

  spacing: {
    xxs: "calc(0.375rem * var(--mantine-scale))", // 6
    xs: "calc(0.75rem * var(--mantine-scale))", // 12
    sm: "calc(1.125rem * var(--mantine-scale))", // 18
    md: "calc(1.5rem * var(--mantine-scale))", // 24
    lg: "calc(2.5rem * var(--mantine-scale))", // 40
    xl: "calc(5rem * var(--mantine-scale))", // 80
    xxl: "calc(11.25rem * var(--mantine-scale))", // 180

    gutter: "calc(2.25rem * var(--mantine-scale))", // 36
  },

  defaultRadius: "md",
  radius: {
    sm: "calc(0.3125rem * var(--mantine-scale))", // 5
    md: "calc(0.5rem * var(--mantine-scale))", // 8
    lg: "calc(0.6875rem * var(--mantine-scale))", // 11
  },

  cursorType: "pointer",

  // Custom
  other: {
    topHeaderHeight: "calc(5.9375rem * var(--mantine-scale))", // 95
    bottomHeaderHeight: "calc(3.75rem * var(--mantine-scale))", // 60
    overlap: "calc(11.25rem * var(--mantine-scale))", // 180
    borderWidth: "calc(0.125rem * var(--mantine-scale))", // 2
    defaultTransitionDuration: "1250ms",
    defaultTransitionFunction: "cubic-bezier(0.19, 1, 0.22, 1)",
    quickTransitionDuration: "200ms",
    quickTransitionFunction: "cubic-bezier(0.19, 1, 0.22, 1)",
    disabledOpacity: 0.5,

    icon: {
      xxs: "calc(.75rem * var(--mantine-scale))", // 12
      xs: "calc(1rem * var(--mantine-scale))", // 16
      sm: "calc(1.25rem * var(--mantine-scale))", // 20
      md: "calc(2rem * var(--mantine-scale))", // 32
      lg: "calc(3rem * var(--mantine-scale))", // 48
      xl: "calc(3.75rem * var(--mantine-scale))", // 60
    },
  },

  // Default Props and Styles for Components
  components: components,
});

export const resolver = (theme) => ({
  variables: {
    "--mantine-top-header-height": theme.other.topHeaderHeight,
    "--mantine-bottom-header-height": theme.other.bottomHeaderHeight,
    "--mantine-overlap": theme.other.overlap,
    "--mantine-border-width": theme.other.borderWidth,
    "--mantine-default-transition-duration":
      theme.other.defaultTransitionDuration,
    "--mantine-default-transition-function":
      theme.other.defaultTransitionFunction,
    "--mantine-quick-transition-duration": theme.other.quickTransitionDuration,
    "--mantine-quick-transition-function": theme.other.quickTransitionFunction,
    "--mantine-disabled-opacity": theme.other.disabledOpacity,

    "--mantine-icon-xxs": theme.other.icon.xxs,
    "--mantine-icon-xs": theme.other.icon.xs,
    "--mantine-icon-sm": theme.other.icon.sm,
    "--mantine-icon-md": theme.other.icon.md,
    "--mantine-icon-lg": theme.other.icon.lg,
    "--mantine-icon-xl": theme.other.icon.xl,

    "--wp--preset--color--black": "var(--mantine-color-black)",
    "--wp--preset--color--transparent-black": "#00000080",
    "--wp--preset--color--white": "var(--mantine-color-white)",
    "--wp--preset--color--theme-blue": "var(--mantine-color-cyan-filled)",
    "--wp--preset--color--theme-orange": "var(--mantine-color-orange-filled)",
    "--wp--preset--color--theme-green": "var(--mantine-color-green-filled)",
    "--wp--preset--color--theme-pink": "var(--mantine-color-ping-filled)",
    "--wp--preset--font-size--extra-small": "var(--mantine-font-size-xs)",
    "--wp--preset--font-size--small": "var(--mantine-font-size-sm)",
    "--wp--preset--font-size--medium": "var(--mantine-font-size-md)",
    "--wp--preset--font-size--large": "var(--mantine-font-size-lg)",
    "--wp--preset--font-size--extra-large": "var(--mantine-font-size-xl)",
    "--wp--preset--font-size--h-1": theme.headings.sizes.h1.fontSize,
    "--wp--preset--font-size--h-2": theme.headings.sizes.h2.fontSize,
    "--wp--preset--font-size--h-3": theme.headings.sizes.h3.fontSize,
    "--wp--preset--font-size--h-4": theme.headings.sizes.h4.fontSize,
    "--wp--preset--font-family--body-font": theme.fontFamily,
    "--wp--preset--font-family--heading-font": theme.headings.fontFamily,
    "--wp--preset--spacing--20": theme.spacing.xxs,
    "--wp--preset--spacing--30": theme.spacing.xs,
    "--wp--preset--spacing--40": theme.spacing.sm,
    "--wp--preset--spacing--50": theme.spacing.md,
    "--wp--preset--spacing--60": theme.spacing.lg,
    "--wp--preset--spacing--70": theme.spacing.xl,
    "--wp--preset--spacing--80": theme.spacing.xxl,
    "--wp--custom--radius--small": theme.radius.sm,
    "--wp--custom--radius--medium": theme.radius.md,
    "--wp--custom--radius--large": theme.radius.lg,
    "--wp--custom--spacing--extra-extra-small": theme.spacing.xxs,
    "--wp--custom--spacing--extra-small": theme.spacing.xs,
    "--wp--custom--spacing--small": theme.spacing.sm,
    "--wp--custom--spacing--medium": theme.spacing.md,
    "--wp--custom--spacing--large": theme.spacing.lg,
    "--wp--custom--spacing--extra-large": theme.spacing.xl,
    "--wp--custom--spacing--extra-extra-large": theme.spacing.xxl,
    "--wp--custom--spacing--overlap": "var(--mantine-overlap)",
    "--wp--style--block-gap": theme.spacing.md,
  },
});
